:root {
  --blue: #0000ff;
  --green: #00cc00;
  --white: white;
  --red: #ff0000;
  --orange: #ff9900;
  --yellow: #ffff00;
}
html {
  min-height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-family: 'Overpass Mono';
}
main.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fade-in 300ms ease-in forwards;
  min-height: 100vh;
  overflow: hidden;
}
a {
  cursor: pointer;
  text-decoration: none;
}
a:visited {
  color: blue;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.home .control {
  text-decoration: none;
  animation: fade-in 500ms ease-in forwards;
  animation-delay: 0.5s;
}

.control {
  cursor: pointer;
  background: blue;
  color: white;
  margin: auto;
  display: inline-block;
  padding: 0.5rem 3rem;
  border-radius: 6px;
  box-shadow: 1px 1px 10px -5px blues, 2px 2px 20px -10px blue;
  transition: box-shadow 200ms ease-in-out;
  margin: auto 0.5rem;
}
.control:hover {
  box-shadow: 1px 1px 10px -2px blue;
}
/* https://paulrhayes.com/animated-css3-cube-interface-using-3d-transforms/ */
#cube-container {
  perspective: 800px;
  perspective-origin: 50% 200px;
  margin: 125px auto;
  outline: none;
}
#cube {
  position: relative;
  margin: auto;
  height: 400px;
  width: 400px;
  transition: transform 0.3s linear;
  transform-style: preserve-3d;
  transform: rotateX(-30deg) rotateY(-30deg);
}
#cube.spin {
  animation: spin-cube 6s ease-in-out infinite;
}
.tile {
  position: absolute;
  margin: 1px;
  border: 4px solid black;
  border-radius: 10px;
  box-sizing: border-box;
  width: 105px;
  height: 105px;
  opacity: 0.3;
}
.LM-left {
  transform: scaleX(-1) translate(0, -60px) rotate(-110deg);
}
.CM-left {
  transform: scaleX(-1) translate(-80px, -45px) rotate(-110deg);
}
.RM-left {
  transform: scaleX(-1) translate(-160px, -20px) rotate(-110deg);
}
.LM-right {
  transform: translate(-220px, 325px) rotate(-230deg);
}
.CM-right {
  transform: translate(-160.5px, 370px) rotate(-230deg);
}
.RM-right {
  transform: translate(-90px, 425px) rotate(-230deg);
}
.UE-left {
  transform: scaleX(-1) translate(270px, 90px) rotate(0deg);
}
.UE-right {
  transform: translate(220px, 85px) rotate(0deg);
}
.CE-right {
  transform: translate(210px, 185px) rotate(0deg);
}
.CE-left {
  transform: scaleX(-1) translate(260px, 180px) rotate(0deg);
}
.DE-left {
  transform: scaleX(-1) translate(250px, 265px) rotate(0deg);
}
.DE-right {
  transform: translate(200px, 265px) rotate(0deg);
}
@keyframes spin-cube {
  0% {
    transform: rotateX(45deg) rotateY(45deg);
  }
  50% {
    transform: rotateX(-315deg) rotateY(-315deg);
  }
  100% {
    transform: rotateX(45deg) rotateY(45deg);
  }
}
@keyframes SpinningSlice-left-z {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(90deg);
  }
}
@keyframes SpinningSlice-right-z {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(-90deg);
  }
}
@keyframes SpinningSlice-left-x {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(90deg);
  }
}
@keyframes SpinningSlice-right-x {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(-90deg);
  }
}
@keyframes SpinningSlice-left-y {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(90deg);
  }
}
@keyframes SpinningSlice-right-y {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(-90deg);
  }
}
